import React from 'react';
import { Flex, ThemeProvider } from "@chakra-ui/core";

import Name from './components/Name';
import abobergTheme from './theme';

function App() {
  return (
    <ThemeProvider theme={abobergTheme}>
      <Flex 
       bg="gray.800" 
       h="full" 
       w="full"
       align="center" 
       direction="column" 
       justify="center"
      >
        <Name name={'Anders Boberg'}/>
      </Flex>
    </ThemeProvider>
  );
}

export default App;
