import React from 'react';
import { Heading, Link } from "@chakra-ui/core";
import '../../animation.css';

const Name = ({name}) => {
  return (
    <Heading fontFamily="name" fontSize={["6xl", "5xl", "4xl", "3xl"]} textAlign="center">
        <Link href="https://andersboberg.com/resume" isExternal animation="colorchange 15s infinite">
          {name}
        </Link>
    </Heading>
  );
}

export default Name;
