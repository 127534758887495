import { theme } from "@chakra-ui/core";

const abobergTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    gray: {
      50: '#eef2fa',
      100: '#d4d7de',
      200: '#b8bcc5',
      300: '#9ca2ad',
      400: '#808796',
      500: '#676e7d',
      600: '#505562',
      700: '#383d46',
      800: '#21252b',
      900: '#080c13',
    }
  },
  fonts: {
    name: "'Playfair Display', serif",
  },
  fontSizes: {
    xs: "18px",
    sm: "20px",
    md: "22px",
    lg: "24px",
    xl: "26px",
    "2xl": "30px",
    "3xl": "34px",
    "4xl": "43px",
    "5xl": "56px",
    "6xl": "64px",
  },
  sizes: {
    ...theme.space,
    full: '100%',
  }
};

export default abobergTheme;
